import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
// import Covers from "../components/strapi/accessories/Covers";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
// import AccessCare from "../components/strapi/accessories/AccessCare";
import Outropiggy from "../components/strapi/structure/Outropiggy";

import Twocol from "../components/strapi/structure/TwoCol";

export default function Accessories({ data }) {
  return (
    <Layout>
      <SEO title="Covers & Accessories" />
      {data.allStrapiCoversAccessories.edges.map(({ node }) => {
        const {
          txtimg_title: accessory_title,
          txtimg_description: accessory_des,
          txtimg_bluetitle: accessory_linkTitle,
          txtimg_bluelink: accessory_link,
          txtimg_img: accessory_img,
        } = node.accessory_all_shop_spa_text_image[0];

        const {
          txtimg_title: chemical_title,
          txtimg_description: chemical_des,
          txtimg_bluetitle: chemical_linkTitle,
          txtimg_bluelink: chemical_link,
          txtimg_img: chemical_img,
          txtimg_order: chemical_order,
        } = node.accessory_all_landing_chemicals_text_image[0];

        const {
          txtimg_title: filter_title,
          txtimg_description: filter_des,
          txtimg_bluetitle: filter_linkTitle,
          txtimg_bluelink: filter_link,
          txtimg_img: filter_img,
        } = node.accessory_all_landing_filter_text_image[0];

        return (
          <React.Fragment key={node}>
            <Masthead
              mhtitle={node.ca_mh.mh_title}
              mhsubtitle={node.ca_mh.mh_subtitle}
              mhbackground={node.ca_mh.mh_bg.publicURL}
            />
            <Twocol
              title={accessory_title}
              description={accessory_des}
              blueTitle={accessory_linkTitle}
              blueLink={accessory_link}
              rowimg={accessory_img.publicURL}
            />

            <Craftsmanship
              title={node.access_craftsmanship.craft_title}
              description={node.access_craftsmanship.craft_description}
            />

            <Twocol
              title={chemical_title}
              description={chemical_des}
              blueTitle={chemical_linkTitle}
              blueLink={chemical_link}
              rowimg={chemical_img.publicURL}
              order={chemical_order}
            />

            <Twocol
              title={filter_title}
              description={filter_des}
              blueTitle={filter_linkTitle}
              blueLink={filter_link}
              rowimg={filter_img.publicURL}
            />

            {/* <AccessCare /> */}
            <Outropiggy outro={node.access_outro.piggy_outro} />
          </React.Fragment>
        );
      })}
    </Layout>
  );
}

export const query = graphql`
  query Accessories {
    allStrapiCoversAccessories {
      edges {
        node {
          id # id of the node
          ca_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          access_craftsmanship {
            id
            craft_title
            craft_description
          }
          access_outro {
            id
            piggy_outro
          }
          accessory_all_landing_chemicals_text_image {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          accessory_all_landing_filter_text_image {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          accessory_all_shop_spa_text_image {
            id
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
        }
      }
    }
  }
`;
